import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import imageUrlFor from '@helpers/imageUrlFor';
import Logo from '@components/Logo';
import Newsletter from '@components/Newsletter';
import Link from '@utility/Link';
import BCorpLogo from '../../../static/images/certifications/b-corp.svg';
import * as styles from './styles.module.scss';
import cn from 'classnames';

const Footer = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allSanitySupport {
          edges {
            node {
              id
              address1
              address2
              phoneNumbers {
                _key
                label
                number
              }
            }
          }
        }
        allSanityFooter {
          edges {
            node {
              id
              _rawBcorp(resolveReferences: { maxDepth: 10 })
              _rawAbout(resolveReferences: { maxDepth: 5 })
              _rawResources(resolveReferences: { maxDepth: 5 })
              social {
                _key
                label
                url
              }
            }
          }
        }
      }
    `,
  );

  const supportNode = query.allSanitySupport.edges[0]?.node;
  const footerNode = query.allSanityFooter.edges[0]?.node;

  const renderRawFooterMenu = (items) => {
    return (
      items && (
        <ul className="sm:mb-10 lg:mb-0">
          {items.map((item) => (
            <li
              key={item._key}
              className={cn(styles.footerLink, {
                [styles.footerLinkMobile]: item.mobile,
              })}
            >
              <Link
                to={item.link[0]}
                className="no-underline-link inline-block"
                styled={false}
                internalExternal
                aria-label={item.link[0].label}
              >
                {item.link[0].label}
              </Link>
            </li>
          ))}
        </ul>
      )
    );
  };

  const renderBCorp = () => (
    <img
      width="100%"
      height="auto"
      src={imageUrlFor(footerNode?._rawBcorp[0]?.image)}
      alt={
        footerNode?._rawBcorp[0]?.link && footerNode?._rawBcorp[0].link[0].label
          ? footerNode?._rawBcorp[0].link[0].label
          : 'Certified B Corp logo'
      }
    />
  );

  return (
    <footer className="print:hidden">
      <div className={`wrapper ${styles.footerInner}`}>
        <div className="row">
          <div className={`col-12 lg:col-3 ${styles.footerColumnFirst}`}>
            <Link to="/" styled={false} title="RBW" aria-label='RBW Logo'>
              <Logo className="mb-4" width="50" height="75" />
            </Link>
            <p className={styles.footerItem}>{supportNode.address1}</p>
            <p className={styles.footerItem}>{supportNode.address2}</p>
            {supportNode.phoneNumbers &&
              supportNode.phoneNumbers.map((phone) => (
                <p key={phone._key} className={styles.footerItem}>
                  {phone.label} {phone.number}
                </p>
              ))}
          </div>
          <div className="col-12 sm:col-6 lg:col-2">
            <h3 className={`${styles.footerHeading} sm-down:hidden`}>About</h3>
            {renderRawFooterMenu(footerNode._rawAbout)}
          </div>
          <div className="col-12 sm:col-6 lg:col-2 sm-down:mb-10">
            <h3 className={`${styles.footerHeading} sm-down:hidden`}>
              Resources
            </h3>
            {renderRawFooterMenu(footerNode._rawResources)}
          </div>
          <div className="col-12 sm:col-6 lg:col-2">
            <h3 className={styles.footerHeading}>Social</h3>
            {footerNode.social && (
              <ul className="sm-down:mb-10">
                {footerNode.social.map((item) => (
                  <li
                    key={item._key}
                    className={cn(styles.footerLink, styles.footerLinkSocial)}
                  >
                    <Link
                      to={item.url}
                      className="no-underline-link inline-block"
                      styled={false}
                      aria-label={item.label}
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="col-12 sm:col-6 lg:col-3 mb-10">
            <h3 className={styles.footerHeading}>Newsletter</h3>
            <Newsletter />
          </div>
        </div>
        <div className="row items-end">
          <div className="col-9">
            <p className="type-sans-030">
              &copy; {new Date().getFullYear()} RBW — All Rights Reserved
            </p>
          </div>
          <div className="col-3">
            <div className="flex justify-end">
              {!!footerNode?._rawBcorp && !!footerNode?._rawBcorp.length && (
                <div>
                  {footerNode?._rawBcorp[0]?.link ? (
                    <Link
                      to={footerNode._rawBcorp[0].link[0]}
                      styled={false}
                      internalExternal
                      aria-label={footerNode._rawBcorp[0].link[0].label}
                    >
                      {renderBCorp()}
                    </Link>
                  ) : (
                    renderBCorp()
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
