import Button from '@components/Button';
import { AccountIcon, CartIcon, SearchIcon } from '@components/Icons';
import Logo from '@components/Logo';
import Overlay from '@components/Overlay';
import SnipcartCounter from '@components/SnipCart/counter';
import { useLocation } from '@reach/router';
import Link from '@utility/Link';
import useNavItems from '@utility/useNavItems';
import cn from 'classnames';
import React from 'react';
import * as styles from './styles';

const DesktopNav = React.memo(({ cartState }) => {
  const navItems = useNavItems();
  const location = useLocation();

  return (
    <div className="wrapper hidden lg:block">
      <div className={cn(styles.navigation, 'row', 'no-transition')}>
        <div className={`col-12 ${styles.navigationContainer}`}>
          <ul className={styles.navigationList}>
            <li className={styles.navigationItem}>
              <Link
                to="/"
                styled={false}
                title="RBW Home Page"
                aria-label="RBW Home Page"
              >
                <Logo
                  className="mt-1/5 rbwNavLogo transition-[height] duration-500 ease-in no-transition"
                  width="54"
                  height="62"
                  aria-hidden="true"
                />
              </Link>
            </li>
            {/* archiving 'products' popup as per https://www.wrike.com/open.htm?id=1094081926
            <li className={styles.navigationItem}>
              <Overlay key={location.href} />
            </li>  */}
            {navItems &&
              navItems?.allSanityNavigation?.edges[0]?.node?._rawNavigationTopLevel.map(
                (item) => (
                  <li className={styles.navigationItem} key={item._key}>
                    <Link
                      to={item}
                      internalExternal
                      underline={false}
                      aria-label={item.label}
                    >
                      {item.label}
                    </Link>
                  </li>
                ),
              )}
          </ul>
          <ul className={styles.navigationList}>
            <li className={cn(styles.navigationItem, styles.navigationIcon)}>
              <Link
                to="/account/projects"
                styled={false}
                title="Login"
                partiallyActive
                className={styles.navigationItemCircle}
                activeClassName={styles.navigationItemCircleActive}
                aria-label="Login"
              >
                <AccountIcon />
                <span className="md:sr-only type-upper-140">Login</span>
              </Link>
            </li>
            <li className={cn(styles.navigationItem, styles.navigationIcon)}>
              <Button
                className="snipcart-checkout"
                title="Cart"
                aria-label="Open Cart"
              >
                <CartIcon />
                <span className="sr-only">Cart</span>
              </Button>
              <SnipcartCounter cartState={cartState} />
            </li>
            <li className={cn(styles.navigationItem, styles.navigationIcon)}>
              <Link
                to="/search"
                styled={false}
                title="Search"
                className={styles.navigationItemCircle}
                activeClassName={styles.navigationItemCircleActive}
                partiallyActive
                onClick={() => {
                  location.pathname === '/search' &&
                    window &&
                    window.history.go(-1);
                }}
                aria-label="Search"
              >
                <SearchIcon />
                <span className="sr-only">Search</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

DesktopNav.displayName = 'DesktopNav';

export default DesktopNav;
